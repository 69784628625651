<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <form
      class="modal-card"
      @submit.prevent="saveData"
    >
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Editar Administrador</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline">
          <div class="column is-12 field">
            <label class="label">Nombre</label>
            <div class="control">
              <input
                required
                class="input"
                :class="{ 'is-danger': vverrors.first('nameUpdate') }"
                type="text"
                placeholder="Nombre"
                name="nameUpdate"
                v-model="nameUpdate"
                v-validate="'required'"
              >
            </div>
          </div>
          <div class="column is-12 field">
            <label class="label">Bloqueado</label>
            <div class="select is-fullwidth">
              <select
                required
                class="input"
                v-model="statusUpdate"
              >
              <option :value="true">SI</option>
              <option :value="false">No</option>
              </select>
            </div>
          </div>
          <div class="column is-12">
           <label class="checkbox">
              <input type="checkbox" v-model="changePass">
              Cambiar contraseña
            </label>
          </div>
          <template v-if="changePass">
            <div class="column is-6 field">
              <label class="label">Contraseña</label>
              <div class="control">
                <input
                  class="input"
                  type="password"
                  placeholder="Contraseña"
                  name="password"
                  v-model="password"
                >
              </div>
            </div>
            <div class="column is-6 field">
              <label class="label">Confirma la contraseña</label>
              <div class="control">
                <input
                  class="input"
                  type="password"
                  placeholder="Confirma la contraseña"
                  name="passwordConfirm"
                  v-model="passwordConfirm"
                >
              </div>
            </div>
            <div class="column is-12">
              <small class="message-red"><em>{{message}}</em></small>
            </div>
          </template>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-danger"
          type="reset"
          @click="close"
        >
          Cancelar
        </button>
        <button
          class="button button-givu"
          type="submit">
          Guardar
        </button>
      </footer>
    </form>
</div>
</template>
<script>
const regexNoLetters = /[^A-Za-zñÑáéíóúÁÉÍÓÚ\s]/g

export default {
  name: 'modalEditAdministrator',
  props: {
    action: {
      type: Function,
      default: () => {}
    },
    close: {
      type: Function,
      default: () => {}
    },
    status: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: ''
    },
    rolId: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      nameUpdate: '',
      lastNameUpdate: '',
      secondLastNameUpdate: '',
      statusUpdate: false,
      password: '',
      passwordConfirm: '',
      changePass: false,
      message: ''
    }
  },
  beforeMount () {
    this.nameUpdate = this.name
    this.statusUpdate = this.status
  },
  watch: {
    nameUpdate (newName) {
      let validText = newName.replace(regexNoLetters, '').replace('  ', ' ')
      if (validText === ' ') validText = ''
      this.nameUpdate = validText
    }
  },
  methods: {
    async saveData () {
      if (await this.$validator.validateAll()) {
        let data = ''
        if (this.password) {
          if (this.password === this.passwordConfirm) {
            data = {
              name: this.nameUpdate,
              lastName: this.lastNameUpdate,
              secondLastName: this.secondLastNameUpdate,
              status: this.statusUpdate,
              password: this.password
            }
            this.action(data)
          } else {
            this.message = 'Las contraseñas no coinciden'
          }
        } else {
          data = {
            name: this.nameUpdate,
            lastName: this.lastNameUpdate,
            secondLastName: this.secondLastNameUpdate,
            status: this.statusUpdate
          }
          this.action(data)
        }
      }
    }
  }
}
</script>

<style scoped>
  .optional {
    font-size:10px;
  }
  .message-red {
    color: red;
  }
</style>
